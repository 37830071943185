import { FC } from 'react';

import { RedirectTo } from './RedirectTo';

const SUBSCRIPTION_PDF_URL =
  'https://storage.googleapis.com/expain_documents/Expain%20Terms%20and%20Conditions.pdf';

const Subscription: FC = () => <RedirectTo src={SUBSCRIPTION_PDF_URL} />;

export default Subscription;
