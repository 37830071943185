import { FC, useEffect } from 'react';

interface Props {
  src: string;
}

export const RedirectTo: FC<Props> = ({ src }) => {
  useEffect(
    function redirectOnLoad() {
      window.location.href = src;
    },
    [src]
  );

  return null;
};
