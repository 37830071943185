import { FC } from 'react';

import { RedirectTo } from './RedirectTo';

const TERMS_PDF_URL =
  'https://storage.googleapis.com/expain_documents/Patient%20App%20Agreement.pdf';

const Terms: FC = () => {
  return <RedirectTo src={TERMS_PDF_URL} />;
};

export default Terms;
